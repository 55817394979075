export const Home = () => {
    return (
        <div>
            <b>
                Hi
            </b>
        </div>
    );
}

export default Home;