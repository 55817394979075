import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { useEffect, useState } from 'react';
import { Person } from '../../common/JpaInterface';
import { useParams } from 'react-router-dom';

export const UserPage = () => {

    const params = useParams();
    const [person, setPerson] = useState<Person>();

    const fetchUser = async (username: string) => {
        try {
            const response = await fetch(`/api/v1/user/${username}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            console.log(`Fetched user ${response}`);
            const success: { users: Person[] } = await response.json();
            setPerson(success.users[0]);
        } catch(e) {
            console.log("Failed to fetch user");
        }
    }

    useEffect(() => {
        if(typeof params.username !== 'undefined') {
            fetchUser(params.username);
        }
    }, [params.username]);

    const userDisplay = () => (
        typeof person !== 'undefined' ? (
            <div>
                <h1>
                    {person.username}
                </h1>
                <h2>
                    {`${person.discoveries.length} discoveries`}
                </h2>
                <DataTable value={person.discoveries}>
                    <Column field="sticker.name" header="Sticker"></Column>
                    <Column field="timestamp" header="Time"></Column>
                </DataTable>
            </div>
        ) : (<></>)
    )

    return (
        <div>
            {userDisplay()}
        </div>
    );
}

export default UserPage;