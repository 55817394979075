import { Card } from "primereact/card";
import { DataView } from "primereact/dataview";
import { Series } from '../../common/JpaInterface';
import { useEffect, useState } from "react";

import styles from './AllSeries.module.scss';
import { Link } from "react-router-dom";

export const AllSeries = () => {
    const [series, setSeries] = useState<Series[]>([]);

    const fetchAllSeries = async () => {
        try {
            const response = await fetch('/api/v1/series/all', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            console.log(`Fetched response ${response}`);
            const success = await response.json();
            setSeries(success.series);
        } catch(e) {
            console.log("Failed to fetch series");
        }
    }
    
    useEffect(() => {
        fetchAllSeries();
    }, []);

    const itemTemplate = (series: Series) => (
        <div className={styles.item}>
            <Card
                title={series.name}
                className={styles.card}
            >
            <p>
                {series.description}
            </p>
            <Link to={series.name}>Go</Link>
        </Card>
        </div>
        
    )

    return (
        <div>
            <DataView
                className={styles.dataView} 
                value={series} 
                itemTemplate={itemTemplate}
                layout='grid'
            />
        </div>
    )

}

export default AllSeries;