import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { useEffect, useState } from 'react';

export const Stickers = () => {

    const [stickers, setStickers] = useState<[{name: string, description: string}]>();
    const fetchAllStickers = async () => {
        try {
            const response = await fetch('/api/v1/sticker/all', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            console.log(`Fetched response ${response}`);
            const success: { stickers: [{name:string, description:string}] } = await response.json();
            setStickers(success.stickers);
        } catch(e) {
            console.log("Failed to fetch stickers");
            return [];
        }
    }

    useEffect(() => {
        fetchAllStickers();
    }, []);

    return (
        <div>
            <DataTable value={stickers}>
                <Column field="name" header="Name"></Column>
                <Column field="description" header="Description"></Column>
            </DataTable>
        </div>
    );
}

export default Stickers;