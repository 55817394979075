import Home from './pages/home/Home';
import Stickers from './pages/sticker/Stickers';
import Layout from './pages/layout/Layout';
import Scan from './pages/scan/Scan';
import SeriesPage from './pages/series/SeriesPage';
import AllSeries from './pages/allseries/AllSeries';
import StickerPage from './pages/sticker/StickerPage';
import Discoveries from './pages/discoveries/Discoveries';
import UserPage from './pages/users/UserPage';
import NoPage from './pages/nopage/NoPage';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import ReactDOM from 'react-dom/client';
import Cookies from 'js-cookie';

import 'primereact/resources/themes/lara-dark-purple/theme.css'
import { useEffect, useState } from 'react';
import { Login } from './common/ContextInterface';
import { createContext } from 'react';
import { v4 as uuidv4 } from 'uuid';

export const LoginContext = createContext<Login>({token: '', username: ''});

export default function App() {

  const basename = document.querySelector('base')?.getAttribute('href') ?? '/'
  const [login, setLogin] = useState<Login>({token: '', username: ''})

  useEffect(() => {
    async function tryLogin() {
      const iAm = async (token:string, username:string) => {
        try {
            const response = await fetch(`/api/v1/user/iam`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                  username,
                  pt: token
                })
            });
            console.log(`Fetched response ${response}`);
            const success = await response.json();
            if(Object.hasOwn(success,'users')) {
              setLogin({token, username});
              Cookies.set('pt',token);
              Cookies.set('username',username);
            }
        } catch(e) {
            console.log("Failed to fetch sticker");
        }
    }
      
      let token = Cookies.get('pt');
      let username = Cookies.get('username')
      
      if(typeof token === 'undefined' || typeof username === 'undefined') {
        token = uuidv4();
        username = `user${uuidv4().slice(0,8)}`;
      }

      await iAm(token, username);
    };
    tryLogin();
  },[])

  return (
    <LoginContext.Provider value={login}>
      <BrowserRouter basename={basename}>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="series" element={<AllSeries />} />
            <Route path="series/:series_name" element={<SeriesPage />} />
            <Route path="series/:series_name/sticker/:sticker_id" element={<StickerPage />} />
            <Route path="stickers" element={<Stickers />} />
            <Route path="discoveries" element={<Discoveries />} />
            <Route path="scan/:uuid" element={<Scan />} />
            <Route path="users/:username" element={<UserPage />} />
            <Route path="*" element={<NoPage />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </LoginContext.Provider>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(<App />);