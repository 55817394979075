import { useEffect, useState, useContext } from "react";
import { Link, useParams } from "react-router-dom";
import { Scan } from "../../common/JpaInterface";
import { LoginContext } from '../../index';

const ScanPage = () => {

    const params = useParams();
    const [scan, setScan] = useState<Scan>();
    const login = useContext(LoginContext);

    useEffect(() => {
        const fetchDiscover = async () => {
            try {
                const response = await fetch(`/api/v1/discovery/discover`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        scan: params.uuid,
                        token: login.token,
                        latitude: 0,
                        longitude: 0
                    })
                });
                console.log(`Fetched response ${response}`);
                const success = await response.json();
                setScan(success);
            } catch(e) {
                console.log("Failed to fetch sticker");
            }
        }
        fetchDiscover();
    }, [params.uuid, login.token]);

    return (
        <div>
            {typeof scan !== 'undefined' && (
                <div>
                    <h1>
                    {scan.message}
                    </h1>
                    <h2>
                        {`${scan.series_no} (${scan.sticker.series.name})`}
                    </h2>
                    <img src={`${process.env.PUBLIC_URL}/img/${scan.sticker.image}`} alt=""/>
                    <Link to={`/series/${scan.sticker.series.name}/sticker/${scan.sticker.id}`}>
                        <strong>
                            To sticker page
                        </strong>
                    </Link>
                </div>
            )}
        </div>
    )
}

export default ScanPage;