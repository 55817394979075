import { DataView } from "primereact/dataview";
import { Series, Sticker} from '../../common/JpaInterface';
import { useEffect, useState } from 'react';
import { Link, useParams } from "react-router-dom";
import styles from './SeriesPage.module.scss';
import 'primeicons/primeicons.css';
import { Panel } from "primereact/panel";

export const SeriesPage = () => {

    const params = useParams();

    const [series, setSeries] = useState<Series>();

    useEffect(() => {
        const fetchSeries = async () => {
            try {
                const response = await fetch(`/api/v1/series/${params.series_name}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });
                console.log(`Fetched response ${response}`);
                const success = await response.json();
                setSeries(success);
            } catch(e) {
                console.log("Failed to fetch stickers");
                return [];
            }
        }

        fetchSeries();
    }, [params.series_name]);

    const stickerTemplate = (sticker: Sticker, index: number) => (
        <div className={styles.item}>
            <Panel header={`#${index+1}`}>
                <Link to={`sticker/${sticker.id}`}>
                    <i className="pi pi-question-circle" style={{ fontSize: '5rem' }}/>
                </Link>   
            </Panel>
            
        </div> 
    )

    const stickerListTemplate = (stickers: Sticker[]) => {
        return stickers.map((sticker, index) => stickerTemplate(sticker, index));
    }

    const googleMapsEmbed = (latitude: number, longitude: number, zoom: number) => (
        <iframe
            title={params.series_name}
            className={styles.maps}
            loading="lazy"
            allowFullScreen
            referrerPolicy="no-referrer-when-downgrade"
            src={`https://www.google.com/maps/embed/v1/view?key=AIzaSyALBb1rUZyfe-gZV2VmnC9p66Mo8hMUzfg
                &center=${latitude},${longitude}
                &zoom=${zoom}
                &maptype=satellite`}>
        </iframe>
    )

    return (
        <div>
            {series !== undefined && (<div>
                <h1>
                    {params.series_name}
                </h1>
                <div className={styles.infoDiv}>
                    <Panel className={styles.mapsPanel} header="Map" toggleable>
                        {googleMapsEmbed(series.latitude,series.longitude,17)}
                    </Panel>
                    <Panel className={styles.stickersPanel} header="Stickers" toggleable>
                        <DataView
                            className={styles.dataView}
                            value={series.stickers} 
                            listTemplate={stickerListTemplate}
                            layout='grid'
                        />
                    </Panel>
                </div>
            </div>)}
        </div>
    );
}

export default SeriesPage;