import { Outlet, useNavigate } from "react-router-dom";
import { Navbar } from '../../components/navbar/Navbar';
import { useContext } from 'react';
import { LoginContext } from '../../index';

const Layout = () => {
    
    const navigate = useNavigate();
    const login = useContext(LoginContext);
    
    return (
        <body>
            <Navbar
                onNavigate={(path: string) => navigate(path)}
                username={login.username}
            />
            <Outlet />
        </body>
    )
};

export default Layout;
