import { Link, useParams } from "react-router-dom";
import { Sticker } from '../../common/JpaInterface';
import { useEffect, useState } from 'react';

const StickerPage = () => {

    const params = useParams();
    const [sticker, setSticker] = useState<Sticker>();

    useEffect(() => {
        const fetchSticker = async () => {
            try {
                const response = await fetch(`/api/v1/sticker/id/${params.sticker_id}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });
                console.log(`Fetched response ${response}`);
                const success = await response.json();
                if(success.stickers.length>0) {
                    setSticker(success.stickers[0]);
                }
            } catch(e) {
                console.log("Failed to fetch sticker");
            }
        }

        
        fetchSticker();
    }, [params.sticker_id]);

    return (
        <div>
            {sticker !== undefined && (
                <div>
                    <h1>
                        {sticker.name}
                    </h1>
                    <h2>
                        {`${sticker.discoveries.length} discoveries`}
                    </h2>
                </div>
            )}
            <Link to={`/series/${params.series_name}`}>
                <strong>
                    Back to Series
                </strong>
            </Link>
        </div>
    )
}

export default StickerPage;