import React from 'react';
import { Menubar } from 'primereact/menubar';
import { MenuItem } from 'primereact/menuitem';
import 'primeicons/primeicons.css';

export const Navbar = ({onNavigate, username}:{onNavigate: any, username: string}) => {
    const items: MenuItem[] = [
        {
            label: 'Home',
            icon: 'pi pi-home',
            command: () => {
                onNavigate("/");
            }
        },
        {
            label: 'Login',
            icon: 'pi pi-sign-in'
        },
        {
            label: 'Series',
            icon: 'pi pi-objects-column',
            command: () => {
                onNavigate("/series");
            }
        },
        {
            label: 'Stickers',
            icon: 'pi pi-qrcode',
            command: () => {
                onNavigate("/stickers");
            }
        },
        {
            label: 'Discoveries',
            icon: 'pi pi-search',
            command: () => {
                onNavigate("/discoveries");
            }
        },
        {
            label: username,
            icon: 'pi pi-user',
            command: () => {
                onNavigate(`/users/${username}`)
            }
        }
    ]

    return (
        <Menubar model={items}/>
    )
}

export default Navbar;