import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { useEffect, useState } from 'react';
import { Discovery } from '../../common/JpaInterface';

export const Discoveries = () => {

    const [discoveries, setDiscoveries] = useState<Discovery[]>();
    const fetchAllDiscoveries = async () => {
        try {
            const response = await fetch('/api/v1/discovery/all', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            console.log(`Fetched response ${response}`);
            const success: { discoveries: Discovery[] } = await response.json();
            setDiscoveries(success.discoveries);
        } catch(e) {
            console.log("Failed to fetch stickers");
        }
    }

    useEffect(() => {
        fetchAllDiscoveries();
    }, []);

    return (
        <div>
            <DataTable value={discoveries}>
                <Column field="person.username" header="User"></Column>
                <Column field="sticker.name" header="Sticker"></Column>
                <Column field="timestamp" header="Time"></Column>
            </DataTable>
        </div>
        
    );
}

export default Discoveries;